import React, {useEffect, useState} from 'react'
import './carousel.css';
import img1 from '../../../Assest/Carousel Images/welding2.jpeg';
import img2 from '../../../Assest/Carousel Images/carousel4.jpeg'
import img3 from '../../../Assest/Carousel Images/bag.jpeg'
import img4 from '../../../Assest/Carousel Images/belts.jpg'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const bg_img = [
    {
        img:img1,
        heading:"Bringing world class quality to every sole"
    },
    {
        img:img2,
        heading:"Great Quality and Design"
    },
    {
        img:img3,
        heading:"Trending And Durable"
    },
    {
        img:img4,
        heading:"Made From Real Leather"
    }

];



const delay = 4000;



const Carousel = () => {
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);
    const[screenSize, setScreenSize] = useState(window.innerWidth);

    const renderScreenSize = () => {
        setScreenSize(window.innerWidth);
        console.log(screenSize);
    }
    useEffect (()=>{
        renderScreenSize();
    })

    function resetTimeout() {
        if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        }
    }


    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(() =>
            setIndex((prevIndex) =>
            prevIndex === bg_img.length - 1 ? 0 : prevIndex + 1
            ), delay);

        return () => {
        resetTimeout();
        };
    }, [index]);

  return (
    <div className="slideshow">
        <div
            className="slideshowSlider"
            style={{ transform: `translateY(${-index * 25}%)` }}>
            
            {screenSize > 1000 ? bg_img.map((bg_img, index) => (
                                    <div
                                        className="slide"
                                        key={index}
                                        style={{ 
                                            background:`url(${bg_img.img})`,
                                            backgroundRepeat:'no-repeat',
                                            backgroundSize:'cover',
                                            backgroundPosition:'center',
                                            objectFit:'contain'
                                            
                                        }}>
                                        <div className='slide_content'>
                                            <p id="slide_heading">{bg_img.heading}</p>
                                            <h2 id="slide_details">{bg_img.details}</h2>
                                        </div>
                                    </div>
                                    ))
                              : bg_img.map((bg_img, index) => (
                                <div
                                    className="slide"
                                    key={index}
                                    style={{ 
                                        background:`url(${bg_img.img})`,
                                        backgroundRepeat:'no-repeat',
                                        backgroundSize:'contain',
                                        backgroundPosition:'center',
                                        objectFit:'contain',
                                        backgroundColor:'black'
                                    }}>
                                    <div className='slide_content'>
                                        <p id="slide_heading">{bg_img.heading}</p>
                                        <h2 id="slide_details">{bg_img.details}</h2>
                                    </div>
                                </div>
                                ))
            }
            
        </div>

        <div className="slideshowDots">
            {bg_img.map((_, idx) => (
            <div
                key={idx}
                className={`slideshowDot${index === idx ? " active" : ""}`}
                onClick={() => {
                setIndex(idx);
                }}
            ></div>
            ))}
        </div>
        <KeyboardArrowUpIcon id="carousel_arrow" style={{fontSize:"50px", color:'white'}}/>
    </div>
  );
}

export default Carousel

import React, {useEffect,useState} from 'react'
import Parallax from '../Parallax/Parallax'
import './products.css'
import bgImg from '../../Assest/leather-products.jpeg'
import AOS from "aos"
import 'aos/dist/aos.css';
import Menu from '../Menu/Menu'
import Header from '../Header/Header'
import ProductsSlide from './ProductsSlide/ProductsSlide';


import {Switch,Route} from 'react-router-dom';

import ImageIcon from '@mui/icons-material/Image';
import { Hidden } from '@material-ui/core';
import Shoes from './Pages/Footwear/Safety_shoes'
import Leather from './Pages/Leather Goods/Leather_Goods'
import Equestrian from './Pages/Equestrian/Equestrian'




const Products = (props) => {

    return (
        <div className="products_page">
            <Header/>
            <Menu/>
            <Parallax title="PRODUCTS" imgSrc={bgImg}/>

            <ProductsSlide/>
            <div className="products_page_container">
                <Switch>
                    <Route exact path="/products">
                        <Shoes/>
                    </Route>
                    <Route exact path="/products/equestrian_products">
                        <Equestrian/>
                    </Route>
                    <Route exact path="/products/leather_products">
                        <Leather/>
                    </Route>
                </Switch>
            </div>

        </div>
    )

 }


export default Products

import './App.css';
// import Header from './Components/Header/Header';
import { Route, Switch } from 'react-router-dom';
import Footer from './Components/Footer/Footer';
import About from './Components/About/About';
import Products from './Components/Products/Products';
import Home from './Components/Home/Home';
import ContactUs from './Components/Contact/ContactUs';


function App() {
  return (
    <div className="App">
     {/* <Header/> */}
     <Switch>

       <Route exact path="/" component={Home}/>

       <Route path='/about' component={About}/>
       
       <Route path='/products' component={Products}/>

       <Route path='/contact' component={ContactUs}/>

     </Switch>
    <Footer/>
    </div>
  );
}

export default App;

import React, {useState,useEffect} from 'react';
import AOS from "aos"
import 'aos/dist/aos.css';


const Equestrian = () => {

    useEffect(() => {
        AOS.init({ duration: 700 });
    }, [])

    const [model, setModel]= useState(false);
    const [tempimgSrc,setTempImageSrc] = useState('');
    const getImg = (imgSrc) => {
        setTempImageSrc(imgSrc);
        setModel(true);
    }

    useEffect(() => {
        model ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
    }, [model]);

    return (
        <div>
            <h1 id="heading_secondary">Equestrian Products</h1>
            {/* <div className="products_cont_left"> 

                <div className={model? "model open" : "model"} >
                    <img src={tempimgSrc}  className = "clickImg" alt=""/>
                    {model ? <CloseIcon onClick={() => setModel(false)}/> : ''}
                </div>

                <div className = "gallery" style={{display:'flex', flexDirection:"column", flexFlow:'wrap'}}>
                    {data.map((item, index)=>{
                        return(
                            <div className = "pics" key ={index} onClick={()=>getImg(item.src) }>
                                <img src={item.src} style={{ width: '300px', objectFit:"contain", margin:"5px" }} data-aos="zoom-in" data-aos-anchor-placement="left-bottom" alt="" /> 
                            </div> 
                        )
                    })}
                </div>
            </div> */}
        </div>
    )
}

export default Equestrian
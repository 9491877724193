import React, {useState, useEffect} from 'react'
import Carousel from './Carousel/Carousel'
import Whatwedo from './Whatwedo/Whatwedo'
import { NavLink } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../Assest/logo_copy.jpeg';
import logo2 from '../../Assest/logo.png';
import tyre from '../../Assest/field_tyre.jpeg';
import welding from '../../Assest/welding.jpeg';
import shoesShob from '../../Assest/shoesSh.jpeg';
import container from '../../Assest/container.jpeg';
import AOS from "aos"
import 'aos/dist/aos.css';
import './Home.css';
import ProductsSlide from '../Products/ProductsSlide/ProductsSlide';

const Home = () => {

    let width = window.innerWidth;
    const[Width,setWidth] = useState(width);
    const [navbarColor, setNavbarColor] = useState(false);

    useEffect(()=>{
        const changeNavbarColor = () => {
            if(window.scrollY >= window.innerHeight){
                setNavbarColor(true);
            }else{
                setNavbarColor(false);
            }
        }
    
        window.addEventListener('scroll', changeNavbarColor);
    })
    
    useEffect(()=>{
        setWidth(window.innerWidth)
        console.log(Width)
    },[width])

    useEffect(() => {
        AOS.init({ duration: 1100 });
    },[])

    const[sidebar, setSidebar] = useState(false);
    const showSideBar = () => setSidebar(!sidebar);

    

    // useEffect(() => {
    //     sidebar ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
    //  }, [sidebar]);


    return (
        <div className="home">
            <div className={navbarColor ? "home_navbar home_navbar_sticky": "home_navbar"}>
                <div className="home_navbar_container">
                    <div className="home_navbar_logo">
                        {navbarColor ? <img className='logo_img' src={logo2} alt="BTIPL"/> : <img className='logo_img' src={logo} alt="BTIPL"/>}
                    </div>

                    <div className= "home_navbar_options">
                        <div className="home_navbar_options_list">
                            <NavLink exact to='/' onClick={showSideBar} activeClassName="home_navbar_link_active" className={navbarColor ? "home_navbar_link_sticky" : "home_navbar_link" }>Home</NavLink >
                            <NavLink to='/about' onClick={showSideBar}  activeClassName="home_navbar_link_active" className={navbarColor ? "home_navbar_link_sticky" : "home_navbar_link" }>About</NavLink >
                            <NavLink to='/products' onClick={showSideBar}  activeClassName="home_navbar_link_active" className={navbarColor ? "home_navbar_link_sticky" : "home_navbar_link" }>Products</NavLink >
                            <NavLink to='/contact' onClick={showSideBar} activeClassName="home_navbar_link_active" className={navbarColor ? "home_navbar_link_sticky" : "home_navbar_link" }>Contact</NavLink >
                        </div>
                    </div>

                    <div className={sidebar ? "home_navbar_side_menu_options home_navbar_side_menu_options_active" : "home_navbar_side_menu_options"}>
                        <NavLink exact to='/' onClick={showSideBar} className={navbarColor ? "home_navbar_link_sticky" : "home_navbar_link" }>Home</NavLink >
                        <NavLink to='/about' onClick={showSideBar}  className={navbarColor ? "home_navbar_link_sticky" : "home_navbar_link" }>About</NavLink >
                        <NavLink to='/products' onClick={showSideBar}  className={navbarColor ? "home_navbar_link_sticky" : "home_navbar_link" }>Products</NavLink >
                        <NavLink to='/contact' onClick={showSideBar} className={navbarColor ? "home_navbar_link_sticky" : "home_navbar_link" }>Contact</NavLink >
                    </div>

                    <div className="home_navbar_hamburger_icon">
                        <MenuIcon style={{fontSize:"30px"}} onClick={showSideBar}></MenuIcon>
                    </div>
                </div>
            </div>
            <Carousel/>
            <ProductsSlide/>

            <div className='description1_cont'>
                <div className='description1'>
                    <div className='left_description1'><div data-aos="fade-right"  >
                        <h1 className='description_line'>Pronounced Quality And design</h1>
                    </div></div>
                    <div className='right_description1'><div data-aos="fade-left">
                        <img src={tyre} alt= ""/>
                    </div></div>
                </div>
            </div>

            <div className='description2'>
                <div className='left_description2'><div data-aos="fade-right">
                    <img src={welding} alt= ""/>
                </div></div>
                <div className='right_description2'><div data-aos="fade-left">
                    <h1 className='description_line'>Top Notch Technology</h1>
                </div></div>
            </div>

            <div className='description1_cont'>
                <div className='description1'>
                    <div className='left_description1'><div data-aos="fade-right">
                        <h1 className='description_line'>Certified By International Standards</h1>  
                    </div></div>
                    <div className='right_description1'><div data-aos="fade-left">
                        <img src={shoesShob} alt= ""/>
                        
                    </div></div>
                </div>
            </div>

            <div className='description2'>
                <div className='left_description2'><div data-aos="fade-right">
                    <img src={container} alt= ""/>
                </div></div>
                <div className='right_description2'><div data-aos="fade-left">
                    <h1 className='description_line'>Water Resistant</h1>
                </div></div>
            </div>
            </div>
    )
}

export default Home

import React, {useEffect,useState} from 'react';
// import basic_img1 from '../../../../Assest/Basic Models/basic1.jpeg';
// import basic_img2 from '../../../../Assest/Basic Models/basic5.jpeg';
// import basic_img3 from '../../../../Assest/Basic Models/basic3.jpeg';
// import basic_img4 from '../../../../Assest/Basic Models/basic4.jpeg';
// import basic_img5 from '../../../../Assest/Basic Models/basic2.jpeg';
import img1 from '../../../../Assest/Shoes Images/1.jpeg';
import img5 from '../../../../Assest/Shoes Images/5.jpeg';
import img9 from '../../../../Assest/Shoes Images/9.jpeg';
import img13 from '../../../../Assest/Shoes Images/13.jpeg';
import img17 from '../../../../Assest/Shoes Images/17.jpeg';
import img21 from '../../../../Assest/Shoes Images/21.jpeg';
import img25 from '../../../../Assest/Shoes Images/25.jpeg';
import img29 from '../../../../Assest/Shoes Images/29.jpeg';
import img33 from '../../../../Assest/Shoes Images/33.jpeg';
import img37 from '../../../../Assest/Shoes Images/37.jpeg';
import AOS from "aos"
import 'aos/dist/aos.css';
import CloseIcon from '@mui/icons-material/Close';
import './Safety_shoes.css';

const Shoes = () => {

    useEffect(() => {
        AOS.init({ duration: 700 });
    }, [])

    // let basic = [
    //     {
    //         src:basic_img1
    //     },
    //     {
    //         src:basic_img2

    //     },
    //     {
    //         src:basic_img3
    //     },
    //     {
    //         src:basic_img4
    //     },
    //     {
    //         src:basic_img5
    //     },
    // ]

    let data = [
        {
            src:img1
        },
        {
            src:img5

        },
        {
            src:img9
        },
        {
            src:img13
        },
        {
            src:img17
        },
        {
            src:img21
        },
        {
            src:img25
        },
        {
            src:img29
        },
        {
            src:img33
        },
        {
            src:img37
        }
    ]

    const [model, setModel]= useState(false);
    const [tempimgSrc,setTempImageSrc] = useState('');
    const getImg = (imgSrc) => {
        setTempImageSrc(imgSrc);
        setModel(true);
    }

    useEffect(() => {
        model ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
    }, [model]);

    return (
        <div>
            <h1 id="heading_secondary">Our Shoe Ranges</h1>
            <div className="products_cont_left"> 

                <div className={model? "model open" : "model"} >
                    <img src={tempimgSrc}  className = "clickImg" alt=""/>
                    {model ? <CloseIcon onClick={() => setModel(false)}/> : ''}
                </div>

                <div className = "gallery" style={{display:'flex', flexDirection:"column", flexFlow:'wrap'}}>
                    {/* {basic.map((item,index) => {
                        return (
                            <div className = "pics" key ={index} onClick={()=>getImg(item.src) }>
                                <img src={item.src} style={{ width: '300px',padding:'55px', objectFit:"contain", margin:"5px" }} data-aos="zoom-in" data-aos-anchor-placement="left-bottom" alt="" /> 
                            </div> 
                        )
                    })} */}
                    {data.map((item, index)=>{
                        return(
                            <div className = "pics" key ={index} onClick={()=>getImg(item.src) }>
                                <img src={item.src} style={{ width: '300px', objectFit:"contain", margin:"5px" }} data-aos="zoom-in" data-aos-anchor-placement="left-bottom" alt="" /> 
                            </div> 
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Shoes
import React from 'react'
import './company_divisions.css';
import FactoryIcon from '@mui/icons-material/Factory';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const CompanyDivisions = (props) => {
    return (
        <div className="company_division">
            <FactoryIcon style={{color:"black", backgroundColor:"#ffcc00", padding:'10px', boxSizing:'content-box'}}/>
            <p id="heading_ternary">{props.heading}</p>
            <p id="desc_ternary"><span><LocationOnIcon style={{color:"#ffcc00", fontSize:'18px'}}/></span>{props.location}</p>
        </div>
    )
}

export default CompanyDivisions

import React from 'react'
import './profile_card.scss';
import EmailIcon from '@mui/icons-material/Email';


const ProfileCard = (props) => {
    return (
        <div className="profile_card_container">
            <div className="profile">
                <h2>{props.name}</h2>
                <p>{props.position}</p>
                <div className="profile_social_links">
                    { 
                        props.linkedinLink !== "" ?  
                        <a className="prof_soc_link" rel="noreferrer" target="_blank" href={props.linkedinLink} ></a>
                        :
                        <div style={{display:'none'}}/>
                    }
                    <span className="prof_soc_link" ><EmailIcon style={{fontSize:'32px' }} onClick={() => window.location = `mailto:${props.mail}`}/></span>
                </div>
            </div>
        </div>
    )
}

export default ProfileCard

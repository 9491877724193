import React from 'react'
import './products_slide.css';
import img1 from '../../../Assest/Product Slider Images/shoe_desc_home Medium.png'
import img2 from '../../../Assest/Product Slider Images/equestrian.png'
import img3 from '../../../Assest/Product Slider Images/leatherGoods Medium.png'
import ProductsCard from './ProductsCard/ProductsCard';
import 'aos/dist/aos.css';

const ProductsSlide = () => {
    return (
            <div className="products_slide_container">
                <ProductsCard src={img1} title="Safety Footwear" link="/products"/>
                <ProductsCard src={img2} title="Equestrian Products" link="/products/equestrian_products"/>
                <ProductsCard src={img3} title="Leather Goods" link="/products/leather_products"/>
            </div>
    )
}

export default ProductsSlide

import React from 'react'
import './productsCard.css';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ProductsCard = (props) => {
    return (
        <div className="products_card">
            <div className="back_face">
                <img src={props.src} alt=""/>
            </div>
            <div className="front_face">
                <h1 id="front_face_head">{props.title}</h1>
                <Link id="front_face_link" to={props.link}>See Products&nbsp; <span><ArrowForwardIosIcon id="arrow_tran" style={{fontSize:"13px",fontWeight:'800' ,marginTop:'6px', transition:"ease-in", transitionDuration:"0.3s"}}/></span></Link>
            </div>
        </div>
    )
}

export default ProductsCard

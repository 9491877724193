import React, {useEffect} from 'react'
import './contact_us.css';
import bgImg from '../../Assest/contact_us.jpeg'
import Parallax from '../Parallax/Parallax';
import ProfileCard from './ProfileCard/ProfileCard';
import { TextField, Button } from '@mui/material';
import CompanyDivisions from './CompanyDivisions/CompanyDivisons';
import AOS from "aos"
import 'aos/dist/aos.css';
import Menu from '../Menu/Menu';
import Header from '../Header/Header';
import emailjs from 'emailjs-com';


const ContactUs = () => {

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_rdx56k5', 'template_nku7bio', e.target, 'uEH039tdI8PC1x1Iz')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    };

    useEffect(() => {
        AOS.init({ duration: 700 });
    }, [])

    return (
        <div>
            <Header/>
            <Menu/>
            <Parallax title="Contact Us" imgSrc={bgImg}/>
            <div className="contact_us">
                <div className="contact_us_container">
                    <div className="sec_one">
                        <div className="sec_one_left">
                            <h1 id="heading_primary">Bestwear International LLP</h1>
                            <h1 id="heading_secondary">Our Divisons</h1>
                            <div className="divisions_container">
                                <div data-aos="fade-right">
                                    <CompanyDivisions 
                                        heading="Factory Address" 
                                        location="2055-B, Katri Piper Khera Unnao, 209861 U.P. (India)"
                                    />
                                </div>
                                <div data-aos="fade-left">
                                    <CompanyDivisions
                                        heading="Head Office" 
                                        location="7/94 Tilak Nagar, Swaroop Nagar Kanpur, 208001 U.P. (India)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sec_one_right">
                            <h1 id="heading_secondary">Reach out to us</h1>
                            <form onSubmit={sendEmail}>
                                <div data-aos="zoom-in">
                                    <TextField 
                                        id="form_name" 
                                        label="Name" 
                                        name='from_name'
                                        variant="outlined" 
                                        required 
                                        type="text" 
                                        style={{width:"100%", margin:'10px 0px'}}
                                    />
                                </div>
                                <div data-aos="zoom-in">
                                    <TextField 
                                        id="form_cmp_name" 
                                        label="Company Name" 
                                        name='company_name'
                                        variant="outlined" 
                                        required 
                                        type="text"
                                        style={{width:"100%", margin:'10px 0px'}}
                                    />
                                </div>
                                <div data-aos="zoom-in">
                                    <TextField 
                                        id="form_email" 
                                        label="Email" 
                                        name='email'
                                        variant="outlined" 
                                        required 
                                        type="email" 
                                        style={{width:"100%", margin:'10px 0px'}}
                                    />
                                </div>
                                <div data-aos="zoom-in">
                                    <TextField 
                                        id="form_mobile" 
                                        label="Phone Number" 
                                        variant="outlined" 
                                        required 
                                        name='number'
                                        type="number"
                                        style={{width:"100%", margin:'10px 0px'}}
                                    /><br/>
                                </div>
                                <div data-aos="zoom-in">
                                    <TextField 
                                        id="query" 
                                        label="Your Query" 
                                        name='query'
                                        variant="outlined" 
                                        required 
                                        type="text"
                                        style={{width:"100%", margin:'10px 0px'}}
                                    />
                                </div>

                                <div data-aos="zoom-in">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        style={{backgroundColor:"#ffcc00", padding:"14px 20px", width:"100%", margin:'10px 0px', color:'black'}}>
                                        Send Message 
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="sec_two">
                        <h1 id="heading_secondary_con">Head Officials</h1>
                        <div className="head_officials_container">
                            <div className="official_profile" data-aos="zoom-in">
                                <ProfileCard 
                                    name="Mohsin Sharif" 
                                    position="Managing Director" 
                                    mail="mohsin@btipl.com"
                                    linkedinLink=""
                                />
                            </div>

                            <div className="official_profile" data-aos="zoom-in">
                                <ProfileCard 
                                    name="Ali Sharif" 
                                    position="Joint Managing Director" 
                                    mail="ali@btipl.com"
                                    linkedinLink="https://www.linkedin.com/in/ali-sharif-90083b17a/"
                                />
                            </div>

                            <div className="official_profile" data-aos="zoom-in">
                                <ProfileCard 
                                    name="Amaan Sharif" 
                                    position="Business Development Manager" 
                                    mail="amaan@btipl.com"
                                    linkedinLink=""
                                />
                            </div>

                            <div className="official_profile" data-aos="zoom-in">
                                <ProfileCard 
                                    name="Abdullah Athar" 
                                    position="Business Development Manager" 
                                    mail="abdullah@btipl.com"
                                    linkedinLink="https://www.linkedin.com/in/abdullah-athar-1306b458/"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs

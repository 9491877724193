import React from 'react'
import './parallax.css'


const Parallax = (props) => {
    return (
        <div 
            className="parallax" 
            style={{
                background:`url(${props.imgSrc})`, 
                backgroundRepeat:'no-repeat', 
                backgroundPosition:'bottom',
                backgroundSize: 'cover',
                backgroundAttachment:'fixed'
                }}
        >
            <h1 id="parallax_heading">{props.title}</h1>
        </div>
    )
}

export default Parallax
